import React, { createElement } from "react";

import { Grid, Cell } from "../../components/ui";
import { FormattedContentItem, ContentObject } from "../../components/content";

import { useStyletron } from "baseui";
import { LabelLarge, ParagraphMedium, HeadingLarge } from "baseui/typography";

import Icons from "../../assets/images/small-icons";
import dotGridBox from "../../assets/images/dot-grid-box.svg";
import dotGridWhiteBox from "../../assets/images/dot-grid-white-box.svg";

import { useTheme } from "../../contexts/theme";

import VisibilitySensor from "react-visibility-sensor";

const WorkAttributes = () => {
  const [css, theme] = useStyletron();
  const { useDarkTheme } = useTheme();

  return (
    <article data-csweb="careers-work-attributes">
      <div
        className={css({
          position: "relative",
          marginTop: theme.sizing.scale800,
          marginBottom: theme.sizing.scale2400,
          [theme.breakpoints.mediaQueries.medium]: {
            marginBottom: theme.sizing.scale1200,
          },
        })}
      >
        <Grid>
          <Cell skip={[0, 0, 1]} span={[4, 8, 10]}>
            <HeadingLarge
              $style={{
                textAlign: "center",
              }}
              marginBottom={["scale1200", "scale2400"]}
            >
              <FormattedContentItem
                scope="Careers"
                accessor={`workAttributes.title`}
              />
            </HeadingLarge>
            <div
              className={css({
                marginTop: theme.sizing.scale2400,
                marginBottom: theme.sizing.scale1600,
                position: "relative",
                [theme.breakpoints.mediaQueries.medium]: {
                  marginTop: theme.sizing.scale1200,
                  marginBottom: theme.sizing.scale1200,
                },
              })}
            >
              <Grid
                flex
                flexGridColumnCount={[1, 1, 3, 3]}
                flexGridColumnGap={["scale100", "scale1000", "scale2400"]}
                flexGridRowGap={["scale1000", "scale1000", "scale1200"]}
              >
                {ContentObject.Careers.workAttributes.items.map(
                  (item, index) => (
                    <Cell key={index} flex>
                      <VisibilitySensor
                        partialVisibility
                        offset={{
                          top: -10000,
                          bottom:
                            typeof window !== "undefined" && window
                              ? window.innerHeight / 4
                              : 0,
                        }}
                      >
                        {({ isVisible }) => (
                          <>
                            {createElement(Icons[item.icon], {
                              className: css({
                                marginLeft: "auto",
                                marginRight: "auto",
                                display: "block",
                                marginBottom: theme.sizing.scale1000,
                                height: "80px",
                                strokeDashoffset: "1500",
                                strokeDasharray: "1500",
                                animationDuration: [
                                  "Chart",
                                  "Clock",
                                  "Planet",
                                ].includes(item.icon)
                                  ? "1000ms"
                                  : "5000ms",
                                animationFillMode: "both",
                                animationTimingFunction: "linear",
                                animationIterationCount: "1",
                                animationName: isVisible
                                  ? {
                                      from: {
                                        strokeDashoffset: "1500",
                                      },
                                      to: {
                                        strokeDashoffset: "0",
                                      },
                                    }
                                  : {},
                              }),
                            })}
                            <LabelLarge $style={{ textAlign: "center" }}>
                              {item.label}
                            </LabelLarge>
                            <ParagraphMedium $style={{ textAlign: "center" }}>
                              <FormattedContentItem
                                scope="Careers"
                                accessor={`workAttributes.items.${index}.text`}
                              />
                            </ParagraphMedium>
                          </>
                        )}
                      </VisibilitySensor>
                    </Cell>
                  )
                )}
              </Grid>
            </div>
            <img
              alt="Dot grid background"
              className={css({
                position: "absolute",
                marginTop: "-500px",
                left: "28%",
                zIndex: "0",
                pointerEvents: "none",
              })}
              src={useDarkTheme ? dotGridWhiteBox : dotGridBox}
            />
          </Cell>
        </Grid>
      </div>
    </article>
  );
};

export default WorkAttributes;
