import React from "react";

import { Layout } from "../../components/layout";
import { SEO } from "../../components/meta";
import { ContentObject } from "../../components/content";

import Heading from "./careers-heading";
import Offers from "./careers-offers";
import Illustration from "./careers-illustration";
import WorkAttributes from "./careers-work-attributes";
import Contact from "./careers-contact";

const Careers = () => (
  <>
    <SEO
      title={ContentObject.Careers.meta.title}
      description={ContentObject.Careers.meta.description}
    />

    <Layout header={{ position: "absolute" }} footer={{ size: "compact" }}>
      <section data-csweb="careers">
        <Heading />
        {ContentObject.Careers.showOffers ? <Offers /> : <Illustration />}
        <WorkAttributes />
        <Contact />
      </section>
    </Layout>
  </>
);

export default Careers;
