import React, { useState } from "react";
import axios from "../../plugins/axios";

import { Grid, Cell, Button, Input } from "../../components/ui";
import {
  ContentObject,
  ContentItem,
  FormattedContentItem,
} from "../../components/content";

import { useStyletron } from "baseui";
import {
  DisplayLarge,
  ParagraphLarge,
  ParagraphXSmall,
  ParagraphMedium,
} from "baseui/typography";
import { FormControl } from "baseui/form-control";
import { useForm } from "react-hook-form";

import dotGridBox from "../../assets/images/dot-grid-box.svg";
import dotGridWhiteBox from "../../assets/images/dot-grid-white-box.svg";

import { useTheme } from "../../contexts/theme";
import { validateEmailFormat } from "../../helpers";

const Contact = () => {
  const { register, errors, handleSubmit, formState } = useForm();
  const { isSubmitting } = formState;

  const [css, theme] = useStyletron();
  const { useDarkTheme } = useTheme();

  const [isFormSent, setIsFormSent] = useState(false);
  const [isError, setIsError] = useState(false);

  async function sendCareersForm({ name, email }) {
    try {
      await axios.post("/careers", {
        name,
        email,
      });

      setIsFormSent(true);
    } catch (error) {
      setIsError(true);
    }
  }

  return (
    <article
      data-csweb="careers-contact"
      className={css({
        paddingTop: theme.sizing.scale800,
        paddingBottom: theme.sizing.scale2400,
        [theme.breakpoints.mediaQueries.medium]: {
          paddingTop: theme.sizing.scale0,
          paddingBottom: theme.sizing.scale0,
        },
      })}
    >
      <Grid>
        <Cell skip={[1, 2, 6]} span={[3, 6, 5]}>
          <div
            className={css({
              position: "relative",
            })}
          >
            <div
              className={css({
                position: "absolute",
                zIndex: "0",
                marginTop: "0%",
                width: "100%",
                height: "40vh",
                opacity: "0.3",
                backgroundColor: theme.colors.backgroundLightAccent,
              })}
            ></div>
          </div>
        </Cell>
        <Cell skip={[0, 0, 1]} span={[4, 8, 10]}>
          <div
            className={css({
              paddingTop: theme.sizing.scale1200,
              paddingBottom: theme.sizing.scale3200,
              position: "relative",
              zIndex: "4",
              [theme.breakpoints.mediaQueries.medium]: {
                paddingTop: theme.sizing.scale2400,
                paddingBottom: theme.sizing.scale2400,
              },
              [theme.breakpoints.mediaQueries.small]: {
                paddingTop: theme.sizing.scale1200,
                paddingBottom: theme.sizing.scale1200,
              },
            })}
          >
            <DisplayLarge
              $style={{
                textAlign: "center",
                [theme.breakpoints.mediaQueries.medium]: {
                  textAlign: "center",
                  ...theme.typography.DisplayMedium,
                  fontWeight: "bold",
                },
                [theme.breakpoints.mediaQueries.small]: {
                  ...theme.typography.DisplayXSmall,
                },
              }}
            >
              <FormattedContentItem scope="Careers" accessor="contact.lead" />
            </DisplayLarge>
            {isFormSent ? (
              <ParagraphMedium
                className={css({
                  textAlign: "center",
                })}
                color={theme.colors.positive}
              >
                <FormattedContentItem
                  scope="Careers"
                  accessor="contact.form.successMessage"
                />
              </ParagraphMedium>
            ) : (
              <form onSubmit={handleSubmit(sendCareersForm)} noValidate>
                <div
                  className={css({
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "baseline",
                    marginTop: theme.sizing.scale1400,
                    marginBottom: theme.sizing.scale1400,
                    marginLeft: "auto",
                    marginRight: "auto",
                    maxWidth: "900px",
                    [theme.breakpoints.mediaQueries.medium]: {
                      flexDirection: "column",
                      alignItems: "center",
                    },
                  })}
                >
                  <FormControl error={errors.name && errors.name.message}>
                    <Input
                      name="name"
                      placeholder={
                        ContentObject.Careers.contact.form.namePlaceholder
                      }
                      inputRef={register({
                        required:
                          ContentObject.Careers.contact.form.requiredError,
                      })}
                      error={errors.name}
                      isRequired
                    />
                  </FormControl>
                  <FormControl
                    overrides={{
                      ControlContainer: {
                        style: {
                          marginLeft: theme.sizing.scale600,
                          marginRight: theme.sizing.scale600,
                        },
                      },
                    }}
                    error={errors.email && errors.email.message}
                  >
                    <Input
                      name="email"
                      placeholder={
                        ContentObject.Careers.contact.form.emailPlaceholder
                      }
                      inputRef={register({
                        required:
                          ContentObject.Careers.contact.form.requiredError,
                        validate: value =>
                          validateEmailFormat(value) ||
                          ContentObject.Careers.contact.form.emailFormatError,
                      })}
                      error={errors.email}
                      isRequired
                    />
                  </FormControl>
                  <Button
                    kind={ContentObject.Careers.contact.form.button.kind}
                    label={ContentObject.Careers.contact.form.button.label}
                    isLoading={isSubmitting}
                    className={css({
                      [theme.breakpoints.mediaQueries.medium]: {
                        width: "100%",
                      },
                    })}
                  >
                    <ContentItem
                      scope="Careers"
                      accessor={`contact.form.button.title`}
                    />
                  </Button>
                </div>
              </form>
            )}
            {isError && (
              <ParagraphXSmall
                $as="div"
                className={css({ textAlign: "center" })}
                color={theme.colors.negative}
              >
                <ContentItem
                  scope="Careers"
                  accessor={`contact.form.apiError`}
                />
              </ParagraphXSmall>
            )}
            <ParagraphLarge
              $style={{
                marginTop: theme.sizing.scale1400,
                justifyContent: "center",
                textAlign: "center",
                fontWeight: "400",
              }}
            >
              <FormattedContentItem
                scope="Careers"
                accessor="contact.subtext"
              />
            </ParagraphLarge>
          </div>
        </Cell>
        <Cell skip={2} span={4}>
          <div
            className={css({
              position: "relative",
              width: "100%",
              height: "100%",
              marginTop: "-420px",
            })}
          >
            <img
              alt="Dot grid background"
              className={css({
                position: "absolute",
                top: "0%",
                left: "0%",
                zIndex: "0",
                pointerEvents: "none",
              })}
              src={useDarkTheme ? dotGridWhiteBox : dotGridBox}
            />
          </div>
        </Cell>
      </Grid>
    </article>
  );
};

export default Contact;
