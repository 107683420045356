import React, { createElement } from "react";
import { navigate } from "gatsby";

import { Grid, Cell } from "../../components/ui";
import { ContentObject } from "../../components/content";

import { useStyletron } from "baseui";
import { DisplayXSmall, LabelLarge, LabelMedium } from "baseui/typography";

import chevronRight from "../../assets/images/chevron-right-black.svg";
import MapMarker from "../../assets/images/map-marker.inline.svg";

const Offers = () => {
  const [css, theme] = useStyletron();

  return (
    <article data-csweb="careers-offers">
      <Grid>
        <Cell skip={[0, 0, 1]} span={[4, 8, 10]}>
          <div
            className={css({
              marginTop: `-${theme.sizing.scale3200}`,
              marginBottom: theme.sizing.scale1200,
              paddingTop: theme.sizing.scale1200,
              paddingBottom: theme.sizing.scale1200,
              position: "relative",
              zIndex: "2",
              [theme.breakpoints.mediaQueries.medium]: {
                marginBottom: theme.sizing.scale200,
              },
            })}
          >
            <Grid
              justifyContent="center"
              flex
              flexGridColumnCount={[1, 1, 3, 3]}
              flexGridColumnGap={["scale100", "scale1000", "scale1200"]}
              flexGridRowGap={["scale1000", "scale1000", "scale1200"]}
            >
              {ContentObject.Jobs.items.map((item, index) => (
                <Cell key={index} flex>
                  <div
                    onClick={() => navigate(`/job/${item.slug}`)}
                    className={css({
                      boxSizing: "border-box",
                      width: "100%",
                      borderTopStyle: "solid",
                      borderRightStyle: "solid",
                      borderBottomStyle: "solid",
                      borderLeftStyle: "solid",
                      borderTopWidth: "1px",
                      borderRightWidth: "1px",
                      borderBottomWidth: "1px",
                      borderLeftWidth: "1px",
                      borderTopColor: theme.colors.backgroundLightAccent,
                      borderRightColor: theme.colors.backgroundLightAccent,
                      borderBottomColor: theme.colors.backgroundLightAccent,
                      borderLeftColor: theme.colors.backgroundLightAccent,
                      backgroundColor: theme.colors.backgroundPrimary,
                      paddingTop: theme.sizing.scale1000,
                      paddingBottom: theme.sizing.scale900,
                      paddingLeft: theme.sizing.scale900,
                      paddingRight: theme.sizing.scale900,
                      transitionProperty: "all",
                      transitionDuration: "200ms",
                      transitionTimingFunction: "ease-in-out",
                      ":hover": {
                        boxShadow: `0px 0px 50px 0px ${theme.colors.servicesCardGlow}`,
                        cursor: "pointer",
                        paddingTop: theme.sizing.scale900,
                        paddingBottom: theme.sizing.scale1000,
                      },
                      ":hover > [data-csweb=offer-title]": {
                        color: theme.colors.primary,
                      },
                      ":hover > [data-csweb=offer-description]": {
                        opacity: "1",
                      },
                      ":hover > [data-csweb=offer-salary-basis]": {
                        opacity: "1",
                      },
                      ":hover > [data-csweb=offer-chevron]": {
                        transform: "translateX(15px)",
                      },
                    })}
                  >
                    <LabelLarge
                      data-csweb="offer-title"
                      $style={{
                        transitionProperty: "all",
                        transitionDuration: "200ms",
                        transitionTimingFunction: "ease-in-out",
                      }}
                    >
                      {item.title}
                    </LabelLarge>
                    <LabelMedium
                      marginTop="scale600"
                      $style={{
                        fontWeight: 400,
                        opacity: 0.3,
                        transitionProperty: "all",
                        transitionDuration: "200ms",
                        transitionTimingFunction: "ease-in-out",
                      }}
                      data-csweb="offer-description"
                    >
                      {item.description}
                    </LabelMedium>
                    <DisplayXSmall marginTop="scale600">
                      {item.salary}
                    </DisplayXSmall>
                    <LabelMedium
                      marginTop="scale300"
                      $style={{
                        fontWeight: 400,
                        opacity: 0.25,
                        transitionProperty: "all",
                        transitionDuration: "200ms",
                        transitionTimingFunction: "ease-in-out",
                      }}
                      data-csweb="offer-salary-basis"
                    >
                      {item.salaryBasis}
                    </LabelMedium>
                    <div
                      className={css({
                        marginTop: theme.sizing.scale1000,
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      })}
                    >
                      {createElement(MapMarker, {
                        className: css({
                          height: "28px",
                          width: "18px",
                          marginRight: theme.sizing.scale400,
                        }),
                      })}
                      <LabelMedium $style={{ fontWeight: 700 }}>
                        {item.site}
                      </LabelMedium>
                      <img
                        alt="More"
                        src={chevronRight}
                        height="14px"
                        data-csweb="offer-chevron"
                        className={css({
                          marginLeft: "auto",
                          transitionProperty: "all",
                          transitionDuration: "200ms",
                          transitionDelay: "100ms",
                          transitionTimingFunction: "ease-in-out",
                        })}
                      />
                    </div>
                  </div>
                </Cell>
              ))}
            </Grid>
          </div>
        </Cell>
      </Grid>
    </article>
  );
};

export default Offers;
