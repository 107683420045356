import React, { createElement } from "react";

import { Grid, Cell } from "../../components/ui";
import { ContentItem } from "../../components/content";

import { useStyletron } from "baseui";
import { ParagraphLarge } from "baseui/typography";

import image from "../../assets/images/careers-illustration.inline.svg";

const Illustration = () => {
  const [css, theme] = useStyletron();

  return (
    <article data-csweb="careers-illustration">
      <Grid>
        <Cell skip={[0, 0, 1]} span={[4, 8, 10]}>
          <div
            className={css({
              marginTop: `-${theme.sizing.scale3200}`,
              marginBottom: theme.sizing.scale1200,
              paddingTop: theme.sizing.scale1200,
              paddingBottom: theme.sizing.scale1200,
              backgroundColor: theme.colors.backgroundPrimary,
              position: "relative",
              zIndex: "2",
              borderTopStyle: "solid",
              borderRightStyle: "solid",
              borderBottomStyle: "solid",
              borderLeftStyle: "solid",
              borderTopWidth: "1px",
              borderRightWidth: "1px",
              borderBottomWidth: "1px",
              borderLeftWidth: "1px",
              borderTopColor: theme.colors.backgroundLightAccent,
              borderRightColor: theme.colors.backgroundLightAccent,
              borderBottomColor: theme.colors.backgroundLightAccent,
              borderLeftColor: theme.colors.backgroundLightAccent,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              [theme.breakpoints.mediaQueries.medium]: {
                marginBottom: theme.sizing.scale200,
              },
            })}
          >
            {createElement(image, {
              className: css({
                margin: "auto",
                display: "block",
                width: "80%",
                strokeDashoffset: "1500",
                strokeDasharray: "1500",
                animationDuration: "2000ms",
                animationFillMode: "both",
                animationTimingFunction: "linear",
                animationIterationCount: "1",
                animationName: {
                  "0%": {
                    opacity: 0,
                    transform: "scale(0)",
                  },
                  "30%": {
                    transform: "scale(1)",
                  },
                  "50%": {
                    opacity: 1,
                    strokeDashoffset: "1500",
                    transform: "scale(1.1)",
                  },
                  "60%": {
                    transform: "scale(1)",
                  },
                  "100%": {
                    strokeDashoffset: "0",
                  },
                },
              }),
            })}
            <ParagraphLarge
              marginBottom="scale0"
              marginTop="scale2400"
              $style={{ opacity: "0.5", textAlign: "center" }}
            >
              <ContentItem scope="Careers" accessor="illustration.subtitle" />
            </ParagraphLarge>
          </div>
        </Cell>
      </Grid>
    </article>
  );
};

export default Illustration;
